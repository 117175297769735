import { buildMapToField, handleQueryResolve } from '../utils'

const uniqBy = require('lodash/uniqBy')

export default function(PatientID) {
    // classic JS with hoist and scope
    // FIXME break those function to their own modules and call them with this[query]
    const _vm = this

    return Promise.all([
        queryPatientProfile(PatientID),
        queryPharmacyInfo(),
        queryPatientMedication(PatientID),
        queryPatientDoctor(PatientID),
    ]).then(patientRecords => patientRecords.reduce((patientData, patientRecord) => ({
        ...patientData,
        ...patientRecord,
    }), {}))

    ///
    function queryPatientMedication(PatientID) {
        // extract Unqiue DINs of Patient
        return _vm.query(`
            SELECT
            rx.DIN
            FROM
            Fillware.dbo.Rx as rx
            WHERE
            rx.PatientId = @PatientID AND rx.DIN IS NOT NULL
            GROUP BY rx.DIN
        `, [{
                param: 'PatientID',
                type: 'sql.Int',
                value: PatientID, // to support like
            }])
            .then(handleQueryResolve)
            .then(results => {
                // get records of each PatientDIN
                const DINS_LIST = Object.values(results).reduce((cur, record) => {
                    if (record.DIN && !Number.isNaN(record.DIN)) {
                        cur.push(record.DIN)
                    }

                    return cur
                }, [])

                // if an empty Array, don't proceed and handle it as a promise error
                if (DINS_LIST.length) {
                    return DINS_LIST
                }

                return null
            })
            .then(listOfPatientDINs => {
                    // console.log(listOfPatientDINs.toString())
                    if (listOfPatientDINs && listOfPatientDINs.length) {
                        return _vm.query(`
          SELECT top 1 with ties
          Rx.RXNumber as RxNumber,
          Rx.DoctorID,
          Doctor.LastName,
          Doctor.FirstName,
          doctor.ID as CPSO,
          DoctorPhone.Phone as DoctorPhone,
          DoctorFax.Phone as DoctorFax,
          Rx.RxDate,
          Rx.DIN,
          DrugRoot.GenericName as GenericName,
          DrugRoot.Strength AS Strength,
          DrugRoot.Form,
          DrugRoot.Schedule,
          DrugRoot.BrandName as BrandName,
          Rx.QtyDispense AS RxQtyDispense,
          Rx.QtyRemain,
          Rx.SIGFULL,
          Rx.QtyDispense,
          Indication.Description,
          Rx.PatientID,
          Rx.Days,
          MixHeader.MixNumber,
          MixHeader.MixName as MixName,
          rx.hold,
          Rx.Status as Status,
          Rx.QtyAuthorize as TotalAuthorized,
          DosR.Brk,
          DosR.Noon,
          DosR.Supp,
          DosR.Bed,
          DosR.IsOTC,
          DosR.IsInactive,
          DosR.ModifiedOn,
          DosR.Modification,
          DosR.PrevRxNumber,
          CEILING((Rx.QtyDispense /Rx.Days)*180) as sixMonth,
          CEILING((Rx.QtyDispense /Rx.Days)*30) as oneMonth,
          ROUND((Rx.QtyDispense /Rx.Days),1) as oneDay,
          RxH.RxNotes,
          RxH.CounselReason
      FROM
          Fillware.dbo.Rx
          LEFT JOIN Fillware.dbo.DrugRoot ON Fillware.dbo.Rx.DIN = Fillware.dbo.DrugRoot.DIN
          LEFT JOIN Fillware.dbo.MixHeader ON Fillware.dbo.Rx.MixNumber = Fillware.dbo.MixHeader.MixNumber
          LEFT JOIN Fillware.dbo.RxHardCopy as RxH ON Fillware.dbo.Rx.RxNumber = RxH.RxNumber
          LEFT JOIN Fillware.dbo.Doctor ON Fillware.dbo.Rx.DoctorID = Fillware.dbo.Doctor.DoctorID
          INNER JOIN
          Fillware.dbo.DoctorAddress as DoctorAddress ON doctor.DoctorID = DoctorAddress.DoctorID AND DoctorAddress.DefaultAddress = 1
          INNER JOIN
          Fillware.dbo.RxExtended as RxExtended on RxExtended.RxNumber = Rx.RxNumber
          LEFT JOIN
          Fillware.dbo.DoctorPhone as DoctorFax ON DoctorFax.AddressID = RxExtended.DoctorAddressID AND DoctorFax.PhoneType = 'FAX'
          LEFT JOIN
          Fillware.dbo.DoctorPhone as DoctorPhone ON DoctorPhone.AddressID = RxExtended.DoctorAddressID AND DoctorPhone.PhoneType = 'BUSINESS'
          LEFT JOIN
          Fillware.dbo.DosR ON DosR.RxNumber = Rx.RxNumber
          LEFT JOIN
          Fillware.dbo.DrugCategories as Indication ON DrugRoot.Therapeutic = Indication.Code
      WHERE
          Rx.RxDate > DATEADD(month, -12, GetDate())
          AND
          Rx.PatientID = @PatientID
          AND (  rx.status = 'T' or rx.status = 'I' or rx.status = 'F' or rx.status = 'NULL' or rx.status is null)
          -- Rx.PatientID = 200
      ORDER BY
          row_number() over (partition by DrugRoot.GenericName, DrugRoot.Strength order by Rx.RxDate DESC)
                    `, [{
                            param: 'PatientID',
                            type: 'sql.Int',
                            value: PatientID, // to support like
                        }])
                    }

                    // empty medications
                    return {
                        medications: [],
                    }
                },

                // handle empty DIN LIST
                () => ({
                    medications: [],
                }))
            .then(handleQueryResolve)
            .then(medicationResults => {
                const listOfNonRepeatedMeds = uniqBy(medicationResults, 'DIN').map((record, index) => ({
                    // [`ID`]: key,
                    DIN: record.DIN,
                    RxNumber: record.RxNumber,
                    RxDate: record.RxDate,
                    QtyDispense: record.QtyDispense,
                    QtyRemain: record.QtyRemain,
                    Days: record.Days,
                    'RX?': record.Schedule === '1' ? 'Yes' : '',
                    'NHP?': '',
                    GenericName: record.GenericName,
                    'OTC?': record.IsOTC === '1' ? 'Yes' : '',
                    onHold: record.hold === 1 ? 'Yes' : '',
                    MED: record.BrandName,
                    'MED STR': record.Strength,
                    Status: record.Status,
                    FORM: record.Form,
                    SIG: record.SIGFULL,
                    INDICATION: record.Description ? [record.Description.toUpperCase()] : '',
                    'MED AM QTY': record.Brk,
                    'MED LUNCH QTY': record.Noon,
                    'MED DINNER QTY': record.Supp,
                    'MED BEDTIME QTY': record.Bed,
                    'MED OTHER QTY': '',
                    'ADHERENCE YES': '',
                    'ADHERENCE NO': '',
                    'PATIENT COMMENT': '',
                    'MED PHARMACIST NOTES': '',
                    'COMMENTS FOR MEDSCHECK RECORD': record.RxNotes,
                    RxNotes: record.RxNotes,
                    CounselReason: record.CounselReason,
                }))

                return {
                    medications: uniqBy(listOfNonRepeatedMeds, med => [med.GenericName, med['MED STR']].join()),
                }
            })
    }

    // TODO OCP Pharmacist's # is under "USERS"
    function queryPharmacyInfo() {
        return _vm.query(`
            SELECT
            DISTINCT sParameterName,
            sParameterValue
            FROM
            Fillware.dbo.SystemParameters
            WHERE
            (
                sParameterName = 'PharmacyName'
                OR
                sParameterName = 'Address'
                OR
                sParameterName = 'City'
                OR
                sParameterName = 'Province'
                OR
                sParameterName = 'Postal'
                OR
                sParameterName = 'Fax'
                OR
                sParameterName = 'Phone'
            )
            AND
            (
                sParameterValue IS NOT NULL
                OR
                sParameterValue != ''
            )
        `, [])
            .then(handleQueryResolve)
            .then(recordset => {
                const MapValuesTo = {
                    PharmacyName: 'PharmacyName',
                    Address: 'PharmacyAddress',
                    City: 'PharmacyCity',
                    Province: 'PharmacyProvince',
                    Postal: 'PharmacyPostal',
                    Fax: 'PharmacyFax',
                    Phone: 'PharmacyPhone',
                }

                const whiteList = Object.keys(MapValuesTo)

                const pharmacyInfo = recordset.reduce((cur, row) => {
                    if (whiteList.indexOf(row.sParameterName) === -1 || !row.sParameterValue) {
                        return cur
                    }

                    return {
                        ...cur,
                        [MapValuesTo[row.sParameterName]]: row.sParameterValue,
                    }
                }, {})

                return buildMapToField(pharmacyInfo)
            })
    }

    function queryPatientProfile(PatientID) {
        return _vm.query(`
            SELECT
            patient.PatientID,
            patient.FirstName,
            patient.LastName,
            null as FamilyDoc,
            patient.OHIP,
            patient.Address,
            patient.City,
            patient.Province,
            patient.Postal,
            patient.EMail,
            patient.HomePhone,
            patient.MobilePhone,
            patient.Allergies,
            patient.DOB,
            patient.Gender,
            patient.LastVisit,
            patient.MedsCheckReminder
            FROM
            Fillware.dbo.Patient as patient
            WHERE
            patient.PatientID = @PatientID
            `, [{
                param: 'PatientID',
                type: 'sql.Int',
                value: PatientID,
            }])
            .then(handleQueryResolve)
            .then(recordset => buildMapToField(recordset[0]))
    }

    // Primary Provider is the top doctor
    function queryPatientDoctor(PatientID) {
        return _vm.query(`
            SELECT
                doctor.DoctorID,
                doctor.FirstName as DoctorFirstName,
                doctor.LastName as DoctorLastName,
                doctor.EMail as DoctorEmail,
                doctor.ID as CPSO,
                DoctorPhone.Phone as DoctorPhone,
                DoctorFax.Phone as DoctorFax,
                DoctorAddress.Address as DoctorAddress,
                DoctorAddress.City as DoctorCity,
                DoctorAddress.Province as DoctorProvince,
                DoctorAddress.Postal as DoctorPostal,
                COUNT(doctor.DoctorID) as RXperProvider
            FROM
                Fillware.dbo.Rx as rx
            INNER JOIN
                Fillware.dbo.Doctor as doctor ON doctor.DoctorID = rx.DoctorID
            INNER JOIN
                Fillware.dbo.DoctorAddress as DoctorAddress ON doctor.DoctorID = DoctorAddress.DoctorID AND DoctorAddress.DefaultAddress = 1
            INNER JOIN
                Fillware.dbo.RxExtended as RxExtended on RxExtended.RxNumber = Rx.RxNumber
            LEFT JOIN
                Fillware.dbo.DoctorPhone as DoctorFax ON DoctorFax.AddressID = RxExtended.DoctorAddressID AND DoctorFax.PhoneType = 'FAX'
            LEFT JOIN
                Fillware.dbo.DoctorPhone as DoctorPhone ON DoctorPhone.AddressID = RxExtended.DoctorAddressID AND DoctorPhone.PhoneType = 'BUSINESS'
            WHERE
                rx.RxDate > DATEADD(year, -1, GetDate()) AND
                rx.PatientID = @PatientID
            GROUP BY
                doctor.DoctorID,
                doctor.FirstName,
                doctor.LastName,
                doctor.EMail,
                doctor.ID,
                DoctorFax.Phone,
                DoctorPhone.Phone,
                DoctorAddress.Address,
                DoctorAddress.City,
                DoctorAddress.Province,
                DoctorAddress.Postal
            ORDER BY RXperProvider DESC
        `, [{
                param: 'PatientID',
                type: 'sql.Int',
                value: PatientID,
            }])
            .then(handleQueryResolve)
            .then(recordset => {
                if (recordset.length) {
                    // reverted back to the old code due that it breaks
                    // primary Telephone and Fax
                    return buildMapToField(recordset[0])
                }

                return {}
            })
    }
}